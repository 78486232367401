// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Link from "../../../../styleguide/components/Link/Link.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_BlogPost from "../../../../routes/common/Routes_BlogPost.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as BlogPostNavbarScss from "./BlogPostNavbar.scss";
import * as Routes_BlogPostCategory from "../../../../routes/common/Routes_BlogPostCategory.res.js";

var css = BlogPostNavbarScss;

function BlogPostNavbar(props) {
  var slug = props.slug;
  var id = props.id;
  var selectedCategory = props.selectedCategory;
  var id$1 = id !== undefined ? Caml_option.valFromOption(id) : 0;
  var slug$1 = slug !== undefined ? slug : "";
  var postsUnderlined = selectedCategory === "BlogPosts" ? Cx.cx([
          css.linkContainer,
          css.underlinedLink
        ]) : css.linkContainer;
  var analyticsUnderlined = selectedCategory === "Analytics" ? Cx.cx([
          css.linkContainer,
          css.underlinedLink
        ]) : css.linkContainer;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_BlogPostCategory.Dashboard.index,
                                  className: css.linkItem,
                                  children: "Categories"
                                }),
                            className: css.linkContainer
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_BlogPost.Dashboard.index,
                                  className: css.linkItem,
                                  children: "Blog Posts"
                                }),
                            className: postsUnderlined
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Link.make, {
                                  href: Routes_BlogPost.Dashboard.analytics,
                                  className: css.linkItem,
                                  children: "Analytics"
                                }),
                            className: analyticsUnderlined
                          }),
                      selectedCategory === "New" ? JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Link.make, {
                                    href: Routes_BlogPostCategory.Dashboard.$$new,
                                    className: css.linkItem,
                                    children: "New Blog Post"
                                  }),
                              className: Cx.cx([
                                    css.linkContainer,
                                    css.underlinedLink
                                  ])
                            }) : (
                          selectedCategory === "Edit" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                  children: [
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Link.make, {
                                                href: Routes_BlogPost.Dashboard.edit(id$1),
                                                className: css.linkItem,
                                                children: "Edit"
                                              }),
                                          className: Cx.cx([
                                                css.linkContainer,
                                                css.underlinedLink
                                              ])
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Link.make, {
                                                href: Routes_BlogPost.show(slug$1),
                                                className: css.linkItem,
                                                children: "View"
                                              }),
                                          className: css.linkContainer
                                        })
                                  ]
                                }) : null
                        )
                    ],
                    className: css.barContainer
                  }),
              className: css.barWrapper
            });
}

var make = BlogPostNavbar;

export {
  css ,
  make ,
}
/* css Not a pure module */
